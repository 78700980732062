import { Component, OnInit, Input, Output, ViewChild, ElementRef } from "@angular/core";
import { FlightService } from "@app/shared/services/flight.service";

@Component({
	selector: "app-extra-service",
	templateUrl: "./extra-service.component.html",
	styleUrls: ["./extra-service.component.scss"],
})
export class ExtraServiceComponent implements OnInit {
	@Input() extras = [];
	@Input() type;
	@Input() id;

	uuid = "";

	constructor(private flightService: FlightService) {
		const kern = "abcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < 12; i++) {
			this.uuid = this.uuid + kern[Math.floor(Math.random() * kern.length)];
		}
	}

	ngOnInit() {
		this.flightService.extraServicesPayload = {};
	}

	addExtraToServiceFromSelect(item, extra) {
		let found = 0;

		for (let i = 0; i < this.flightService.extraServicesPayload[this.uuid].length; i++) {
			if (
				this.flightService.extraServicesPayload[this.uuid][i]["service_id"] ===
				extra.ServiceId
			) {
				found = 1;
				this.flightService.extraServicesPayload[this.uuid][i]["options"] = item;
				break;
			}
		}
		if (found === 0) {
			this.flightService.extraServicesPayload[this.uuid].push({
				service_id: extra.ServiceId,
				behavior: extra.Behavior,
				name: extra.Name,
				pax_id: this.id,
				options: item,
			});
		}
	}

	addExtraToServiceFromCheckbox(item, extra) {
		
		let found = 0;
		for (let i = 0; i < this.flightService.extraServicesPayload[this.uuid].length; i++) {
			if (
				this.flightService.extraServicesPayload[this.uuid][i]["service_id"] ===
				extra.ServiceId
			) {
				found = 1;
				this.flightService.extraServicesPayload[this.uuid][i]["options"].push(item);
				break;
			}
		}
		if (found === 0) {
			this.flightService.extraServicesPayload[this.uuid].push({
				service_id: extra.ServiceId,
				behavior: extra.Behavior,
				name: extra.Name,
				pax_id: this.id,
				options: [item],
			});
		}

	
	}

	checkboxEvent(e, item, extra) {
		if (e.target.checked) {
			if (!this.flightService.extraServicesPayload[this.uuid]) {
				this.flightService.extraServicesPayload[this.uuid] = [];
			}
			this.addExtraToServiceFromCheckbox(item, extra);
		} else {
		}
	}

	selectEvent(e, extra) {
		let item = extra.options.filter((element) => element.code === e.target.value);
		if (!this.flightService.extraServicesPayload[this.uuid]) {
			this.flightService.extraServicesPayload[this.uuid] = [];
		}
		if (item.length > 0) {
			this.addExtraToServiceFromSelect(item, extra);
		}
	}

	clearExtras() {
		this.flightService.extraServicesPayload = {};
		var selects = document.getElementsByTagName("select");
		for (let i = 0; i < selects.length; i++) {
			if (selects[i].classList.contains("_" + this.uuid)) {
				selects[i].selectedIndex = 0;
			}
		}

		var checkboxes = document.getElementsByTagName("input");
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].classList.contains("u_" + this.uuid)) {
				checkboxes[i].checked = false;
			}
		}
	}

	getClassName() {
		return "_" + this.uuid;
	}

	getClassNameCheckBox() {
		return "u_" + this.uuid;
	}
}
