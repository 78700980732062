import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { COMMUNES } from "@app/shared/services/misc/mock-communes";
import { FlightService } from "../../../shared/services/flight.service";
import { FlightPassengerCommonModel } from "../flight-passenger-models/flight.passenger.common.model";
import { FlightPassengerInfantModel } from "../flight-passenger-models/flight.passenger.infant.model";
import { FlightPassengerClientModel } from "../flight-passenger-models/flight.passenger.client.model";
import { LocalizationService } from "../../../shared/services/localization.service";
import { DateService } from "../../../shared/services/date.service";
import { ShareVars } from "../../../shared/services/share.vars";
import { BaseService } from "../../../shared/services/base.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { cloneDeep } from "lodash";
import { ERROR_MESSAGE_MINOR_SINGLE_NOT_ALLOWED } from "../../../shared/services/display.messages";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomDatepickerI18nService,
	I18n,
} from "@app/shared/services/custom-datepicker-i18n.service";
import { countryCodes } from "assets/common/countryPhoneCodes";
import { environment } from "../../../../environments/environment";

const INFANT_MAX_AGE = 2;
const INFANT_MIN_AGE = 0;
const ADULT_MAX_AGE = 130;
const ADULT_MIN_DATE = 12;
const CHILD_MIN_AGE = 2;
const CHILD_MAX_AGE = 12;
const MINOR_AGE = 18;

const YOUTH_MIN_DATE = 12;
const YOUTH_MAX_DATE = 24;
const SENIOR_MIN_DATE = 60;

const ADT = "ADT";
const CHD = "CHD";
const INF = "INF";
const INFS = "INS";
const YTH = "YTH";
const SNR = "SRC";

@Component({
	selector: "app-flight-passenger",
	templateUrl: "./flight-passenger.component.html",
	styleUrls: ["./flight-passenger.component.scss"],
	providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }],
})
export class FlightPassengerComponent implements OnInit {
	tokenVerificationRequest$ = null;
	departements;
	towns;

	user = null;
	userType = null;
	minDate;
	maxDateInfantBD;
	minDateInfantBD;
	maxDateAdultBD;
	minDateAdultBD;
	maxDateChildBD;
	minDateChildBD;
	maxDateSeniorBD;
	minDateSeniorBD;
	maxDateyouthBD;
	minDateyouthBD;
	env = environment;

	validNamesAdult = [true];
	validNamesInfant = [true];
	validNamesChild = [true];

	validNamesInfantSeat = [true];
	validNamesYouth = [true];
	validNamesSenior = [true];

	onholdbooking: boolean = false;
	corporateAuthorityToBook: boolean = false;

	flightDeparturesLimit: number = 3;
	flightReturnsLimit: number = 3;
	@Input() object = {};
	@Output() onQuit = new EventEmitter();

	/* form variables */
	clientInfos: FlightPassengerClientModel = new FlightPassengerClientModel();
	adultsInfosArray = new Array();
	childrenInfosArray = new Array();
	infantsInfosArray = new Array();

	infantsSeatInfosArray = new Array();
	youthInfosArray = new Array();
	seniorInfosArray = new Array();

	flightVars;
	isDomesticFlight: boolean = false;

	canBeBookedOnhold: boolean = false;
	allowMinorSinglePassenger: boolean = false;

	provider: string;

    phonePattern: RegExp = /^\d{8,11}$/;
	emailPattern: string = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$";
	noPattern: string = "";

	clientCountryCodeDropdown = {
		mainButtonID: "flight_passenger_pax_client_countrycode_class_dropdown",
		mainButtonText: "Algerie +213",
		dropdownAriaID: "flight_passenger_pax_client_countrycode_class_dropdown_aria",
		ariaButtonsID: [],
		ariaButtonsText: ["+213 Algerie"],
		dropdownTypeString: true,
	};

	clientCitiesDropdown = {
		mainButtonID: "flight_passenger_pax_client_city_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_city_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientTownsDropdown = {
		mainButtonID: "flight_passenger_pax_client_town_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_town_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		dropdownTypeString: false,
	};

	clientCountriesDropdown = {
		mainButtonID: "flight_passenger_pax_client_country_class_dropdown",
		dropdownAriaID: "flight_passenger_pax_client_country_class_dropdown_aria",
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: ["Algérie", "Liban"],
		dropdownTypeString: false,
	};

	nationalityDropdown = {
		mainButtonID: null,
		dropdownAriaID: null,
		mainButtonText: null,
		ariaButtonsID: [],
		ariaButtonsText: [],
		ariaButtonsTextTranslation: {},
		dropdownTypeString: false,
	};

	shareVarsRef = ShareVars;
	isZoneB: boolean = false;

	extraFields = new Array();
	corporate = false;
	flightProvider = "";
	requiredCustomerAddress = false;
	request = {};
	civilityMap = {
		"Ms.": "S",
		Ms: "S",
		"Mr.": "M",
		Mr: "M",
		"Mrs.": "F",
	};

	constructor(
		private flightService: FlightService,
		private navigationService: NavigationService,
		private localizationService: LocalizationService,
		private baseService: BaseService
	) {
		for (let i = 0; i < this.clientCountryCodeDropdown.ariaButtonsText.length; i++) {
			this.clientCountryCodeDropdown.ariaButtonsID[i] =
				"flight_passeneger_pax_client_countrycode_" + i;
		}

		for (let i = 0; i < this.clientCountriesDropdown.ariaButtonsText.length; i++) {
			this.clientCountriesDropdown.ariaButtonsID[i] =
				"flight_passenger_pax_client_country_" + i;
		}

		this.clientCountryCodeDropdown.ariaButtonsText = countryCodes.map(
			(item, i) => item.country + " +" + item.code
		);
	}

	ngOnInit() {
		this.provider = this.object["recommendation"]["provider"];
		this.requiredCustomerAddress = this.flightService.requiredCustomerAddress;
		this.flightVars = this.flightService.flightVars;
		this.flightProvider = this.flightService.getBookingFlightProvider();
		this.isZoneB = this.flightService.isZoneB(this.provider);
		this.isDomesticFlight = this.flightService.getDomesticFlight();
		this.canBeBookedOnhold = this.flightService.canBeBookedOnhold;
		this.allowMinorSinglePassenger = this.flightService.allowMinorSinglePassenger;

		let flightDeparture = this.flightService.getSearchData().departureDate_1;
		if (this.flightVars.nrOfAdult > 0) {
			this.validNamesAdult = new Array(this.flightVars.nrOfAdult).fill(false);
			this.adultsInfosArray = new Array(this.flightVars.nrOfAdult)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateAdultBD = DateService.convertDateInPastYear(
				ADULT_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateAdultBD = DateService.convertDateInPastYear(
				ADULT_MIN_DATE,
				flightDeparture,
				false
			);
		}

		if (this.flightVars.nrOfChild > 0) {
			this.validNamesChild = new Array(this.flightVars.nrOfChild).fill(false);
			this.childrenInfosArray = new Array(this.flightVars.nrOfChild)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateChildBD = DateService.convertDateInPastYear(
				CHILD_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateChildBD = DateService.convertDateInPastYear(
				CHILD_MIN_AGE,
				flightDeparture,
				false
			);
		}

		if (this.flightVars.nrOfInfant > 0) {
			this.validNamesInfant = new Array(this.flightVars.nrOfInfant).fill(false);
			this.infantsInfosArray = new Array(this.flightVars.nrOfInfant)
				.fill(0)
				.map((e) => new FlightPassengerInfantModel());
			this.maxDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MIN_AGE,
				flightDeparture,
				false
			);
		}

		if (this.flightVars.nrOfInfantWithSeat > 0) {
			this.validNamesInfantSeat = new Array(this.flightVars.nrOfInfantWithSeat).fill(false);
			this.infantsSeatInfosArray = new Array(this.flightVars.nrOfInfantWithSeat)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.minDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MIN_AGE,
				flightDeparture,
				false
			);
			this.maxDateInfantBD = DateService.convertDateInPastYear(
				INFANT_MAX_AGE,
				flightDeparture,
				true
			);
		}

		if (this.flightVars.nrOfYouth > 0) {
			this.validNamesYouth = new Array(this.flightVars.nrOfYouth).fill(false);
			this.youthInfosArray = new Array(this.flightVars.nrOfYouth)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateyouthBD = DateService.convertDateInPastYear(
				YOUTH_MAX_DATE,
				flightDeparture,
				true
			);
			this.minDateyouthBD = DateService.convertDateInPastYear(
				YOUTH_MIN_DATE,
				flightDeparture,
				false
			);
		}

		if (this.flightVars.nrOfSenior > 0) {
			this.validNamesSenior = new Array(this.flightVars.nrOfSenior).fill(false);
			this.seniorInfosArray = new Array(this.flightVars.nrOfSenior)
				.fill(0)
				.map((e) => new FlightPassengerCommonModel());
			this.maxDateSeniorBD = DateService.convertDateInPastYear(
				ADULT_MAX_AGE,
				flightDeparture,
				true
			);
			this.minDateSeniorBD = DateService.convertDateInPastYear(
				SENIOR_MIN_DATE,
				flightDeparture,
				false
			);
		}

		if (Object.keys(this.object).length > 0) {
			if (this.shareVarsRef.departements.length > 0) {
				this.departements = this.shareVarsRef.departements;
				this.initDepartementsDpd();
			} else if (environment.mode == "B2C") {
				this.localizationService.getDepartements().subscribe((data) => {
					this.departements = data;
					this.shareVarsRef.departements = data;
					this.initDepartementsDpd();
				});
			}
			if (this.shareVarsRef.countryCodes.length > 0) {
				this.initCountriesDpd();
			} else {
				this.localizationService.getCountries().subscribe((data) => {
					this.shareVarsRef.setCountriesInfo(data);

					this.initCountriesDpd();
				});
			}

			switch (this.baseService.b2bUserType) {
				case "B2C":
					this.userType = "B2C";
					this.initClientInfos(this.shareVarsRef.currentUser);
					break;

				case "B2B":
					this.userType = "B2B";
					let client = new FlightPassengerClientModel();
					if (this.shareVarsRef.currentAgency["corporate"]) {
						//@ts-ignore
						client = this.shareVarsRef.currentAgency;
						this.corporate = true;
					}
					this.initClientInfos(client);
					break;
			}

			this.minDate = DateService.getTodayDate();
		}

		this.extraFields = this.flightService.getExtraFieldsArray();
		if (this.extraFields) {
			for (let i = 0; i < this.extraFields.length; i++) {
				let extraField = this.extraFields[i];
				extraField.ariaButtonsText = [];
				extraField.ariaButtonsTextTranslation = [];
				for (let j = 0; j < extraField.values.length; j++) {
					let fieldValue = extraField.values[j];
					if (j == 0) {
						extraField.selectedValue = fieldValue.code;
					}
					extraField.ariaButtonsText[j] = fieldValue.code;
					extraField.ariaButtonsTextTranslation[fieldValue.code] = fieldValue.description;
				}
			}
		}
	}

	initDepartementsDpd() {
		for (let i = 0; i < this.departements.length; i++) {
			this.clientCitiesDropdown.ariaButtonsID[i] = "flight_passenger_pax_client_city_" + i;
			this.clientCitiesDropdown.ariaButtonsText[i] = this.departements[i]["name"];
		}

		this.towns = COMMUNES["Alger"];
		this.clientInfos.clientTown = COMMUNES["Alger"][0]["name"];

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientCountriesDropdown.mainButtonText = "Algérie";

		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsID[i] = "flight_passenger_pax_client_town_" + i;
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}

	initCountriesDpd() {
		this.nationalityDropdown.ariaButtonsText = this.shareVarsRef.countryCodes;
		this.nationalityDropdown.ariaButtonsTextTranslation =
			this.shareVarsRef.countryNameDictionnary;

		for (let i = 0; i < this.adultsInfosArray.length; ++i) {
			this.nationalityDropdown.ariaButtonsID.push([]);

			this.setNatAriaBtnID(i);
		}
	}

	setNatAriaBtnID(adultIndex) {
		for (let i = 0; i < this.nationalityDropdown.ariaButtonsText.length; i++) {
			this.nationalityDropdown.ariaButtonsID[adultIndex][i] =
				"flight_passenger_pax_adult_nationality_" + adultIndex;
		}
	}

	initClientInfos(user) {
		this.user = user;

		if (this.user.address) {
			this.clientInfos.clientAddress = this.user.address;
		}

		if (this.user.phone_number) {
			this.clientInfos.clientPhoneNumber = this.user.phone_number;
		}

		if (this.user.town) {
			this.clientInfos.clientTown = this.user.town;
			this.clientTownsDropdown.mainButtonText = this.clientInfos.clientTown;
		}

		if (this.user.email) {
			this.clientInfos.clientEmail = this.user.email;
		}

		if (this.user["country"]) {
			this.clientCountriesDropdown.mainButtonText = this.user["country"];
		}

		this.clientCitiesDropdown.mainButtonText = this.clientInfos.clientWilaya;
	}

	setCountryCode(code: string) {
		this.clientInfos.clientCountryCode = code.split("+").pop();
	}

	setWilaya(wilayaName) {
		this.towns = COMMUNES[wilayaName];
		this.clientInfos.clientTown = this.towns[0]["name"];
		this.clientInfos.clientWilaya = wilayaName;

		this.clientTownsDropdown.mainButtonText = this.towns[0]["name"];
		this.clientTownsDropdown.ariaButtonsText = [];
		for (let i = 0; i < this.towns.length; i++) {
			this.clientTownsDropdown.ariaButtonsText[i] = this.towns[i]["name"];
		}
	}

	setTown(townName) {
		this.clientInfos.clientTown = townName;
	}

	setCountry(countryName) {
		this.clientInfos.clientCountry = countryName;
	}

	setBillingTab() {
		this.createConfirmationRequest();

		if (this.baseService.b2bUserType == "B2B") {
			this.savePaxData();
		}

		this.flightService.setConfirmationRequest(this.request);
		this.flightService.setExtrasServicePayload(this.flightService.extraServicesPayload);

		const elm: HTMLElement = document.getElementById("pax_billing_info_tab") as HTMLElement;
		ShareVars.enableBillingInfoTab = true;
		elm.classList.remove("disbaled-tab");
		elm.click();
		this.scrollToTop();
		this.onQuit.emit(this.flightService.extraServicesPayload);
	}

	openBillingTab() {
		if (this.allowMinorSinglePassenger) {
			this.setBillingTab();
			return;
		}
		if (this.checkMinorSinglePassenger()) {
			this.setBillingTab();
			return;
		}
		ShareVars.showError = true;
		ShareVars.errorMessage = ERROR_MESSAGE_MINOR_SINGLE_NOT_ALLOWED;
	}

	savePaxData() {
		this.flightService.clientInfos = cloneDeep(this.clientInfos);
		this.flightService.adultsInfosArray = cloneDeep(this.adultsInfosArray);
		this.flightService.childrenInfosArray = cloneDeep(this.childrenInfosArray);
		this.flightService.infantsInfosArray = cloneDeep(this.infantsInfosArray);

		this.flightService.infantsSeatInfoArray = cloneDeep(this.infantsSeatInfosArray);
		this.flightService.youthsInfoArray = cloneDeep(this.youthInfosArray);
		this.flightService.seniorInfoArray = cloneDeep(this.seniorInfosArray);

		this.flightService.onholdbooking = this.onholdbooking;
		this.flightService.corporate = this.corporate;

		this.flightService.setExtraFieldsArray(this.extraFields);
	}

	setBillingInfos() {
		this.createConfirmationRequest();

		if (this.baseService.b2bUserType == "B2B") {
			this.savePaxData();
		}

		this.flightService.setConfirmationRequest(this.request);

		this.shareVarsRef.enableBillingInfoTab = true;
		this.scrollToTop();
	}

	getBillingInfos() {
		if (this.allowMinorSinglePassenger) {
			this.setBillingInfos();
			return;
		}
		if (this.checkMinorSinglePassenger()) {
			this.setBillingInfos();
			return;
		}
		ShareVars.showError = true;
		ShareVars.errorMessage = ERROR_MESSAGE_MINOR_SINGLE_NOT_ALLOWED;
	}

	checkMinorSinglePassenger(): boolean {
		let foundAdult = new Array(this.adultsInfosArray.length).fill(false);
		const minorAgeDate = DateService.convertDateInPastYear(
			MINOR_AGE,
			this.flightService.getSearchData().departureDate_1,
			false
		);

		if (this.adultsInfosArray.length == 0) {
			return false;
		}
		this.adultsInfosArray.forEach((element, index) => {
			switch (this.baseService.compareDates(minorAgeDate, element.birthDate)) {
				case 0:
				case 1:
					foundAdult[index] = true;
					return;

				case -1:
					foundAdult[index] = false;
					break;
			}
		});

		return foundAdult.includes(true);
	}

	setPassengersRequestParams(n, tab, type: string, index: number): any {
		if (n > 0) {
			let p = this.object["recommendation"]["price"]["breakdown"][type];
			this.request[type + "_totalFare"] = p["totalFare"];
			this.request[type + "_tax"] = p["totalTax"];

			for (let i = 0; i < tab.length; i++) {
				if ((tab[i].passportExpDate && tab[i].passportNumber) || !this.isDomesticFlight) {
					this.request["addTravellerPassportNumber_" + index] = true;
					this.request["travellerPassportExp_" + index] = DateService.toStringDate(
						tab[i].passportExpDate
					);
					this.request["travellerPassportNumber_" + index] = tab[i].passportNumber;
				} else {
					this.request["travellerPassportExp_" + index] = null;
					this.request["travellerPassportNumber_" + index] = null;
				}

				if (tab[i].idCardExpDate && tab[i].idCardNumber) {
					this.request["travellerIDCardExp_" + index] = DateService.toStringDate(
						tab[i].idCardExpDate
					);
					this.request["travellerIDCardNumber_" + index] = tab[i].idCardNumber;
				} else {
					this.request["travellerIDCardExp_" + index] = null;
					this.request["travellerIDCardNumber_" + index] = null;
				}

				if (tab[i].frequentFlyerId && tab[i].frequentFlyerId.length) {
					this.request[`frequentFlyer_${index}`] = tab[i].frequentFlyerId
				}
				this.request["travellerFirstName_" + index] = tab[i].firstName;
				this.request["travellerMiddleName_" + index] = tab[i].middleName;
				this.request["travellerLastName_" + index] = tab[i].lastName;
				this.request["travellerNat_" + index] = tab[i].nationality;
				this.request["travellerSex_" + index] = this.civilityMap[tab[i].civility];

				//this.request["travellerSex_" + index] = ((tab[i].civility == "Mr") ? "M" : "F");
				this.request["travellerType_" + index] = type;

				try {
					tab[i].birthDate && (this.request["travellerBirth_" + index] = DateService.toStringDate(tab[i].birthDate))
				} catch {}

				if (tab[i].employeeID) {
					this.request["employeeID_" + index] = tab[i].employeeID;
				}

				if (type == ADT) {
					this.request["travellerWithInfant_" + (i + 1)] = 0;
				}

				type == INF
					? (this.request["travellerWithInfant_" + (i + 1)] = index)
					: (this.request["specialServiceRequested_" + index] = tab[i].specialServices);

				index++;
			}
		}
		return index;
	}

	createConfirmationRequest(): any {
		this.request = this.flightService.request;
		const adultResult = this.setPassengersRequestParams(
			this.flightVars.nrOfAdult,
			this.adultsInfosArray,
			ADT,
			1
		);

		const childResult = this.setPassengersRequestParams(
			this.flightVars.nrOfChild,
			this.childrenInfosArray,
			CHD,
			adultResult
		);

		const youthResult = this.setPassengersRequestParams(
			this.flightVars.nrOfYouth,
			this.youthInfosArray,
			YTH,
			childResult
		);

		const seniorResult = this.setPassengersRequestParams(
			this.flightVars.nrOfSenior,
			this.seniorInfosArray,
			SNR,
			youthResult
		);

		const infantSeatResult = this.setPassengersRequestParams(
			this.flightVars.nrOfInfantWithSeat,
			this.infantsSeatInfosArray,
			INFS,
			seniorResult
		);

		this.setPassengersRequestParams(
			this.flightVars.nrOfInfant,
			this.infantsInfosArray,
			INF,
			infantSeatResult
		);

		this.request["customer_city"] =
			this.clientInfos.clientWilaya + "-" + this.clientInfos.clientTown;
		this.request["customer_country"] = this.clientInfos.clientCountry;
		this.request["customer_email"] = this.clientInfos.clientEmail;
		this.request["customer_phoneNumber"] = this.clientInfos.clientPhoneNumber;
		this.request["customer_phone_countrycode"] = this.clientInfos.clientCountryCode;
		this.request["customer_address"] = this.clientInfos.clientAddress.trim();
		this.request["point_of_sale_id"] = null;
		this.request["card_type"] = null;
		this.request["card_number"] = null;
		this.request["card_exp_month"] = null;
		this.request["card_exp_year"] = null;
		this.request["onholdbooking"] = this.onholdbooking;
		this.request["openReturn"] = this.flightService.openReturn;
		this.request["extraservices"] = this.flightService.getExtraServices();

		if (this.extraFields) {
			for (let i = 0; i < this.extraFields.length; i++) {
				this.request["extra_field_" + this.extraFields[i].name] =
					this.extraFields[i].selectedValue;
			}
		}
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	scrollToTop() {
		let elm: HTMLElement = document.getElementById("nav_pax_tabs") as HTMLElement;
		setTimeout(() => {
			elm.scrollIntoView({ behavior: "smooth" });
		}, 500);
	}

	goToFlightAvailability() {
		this.navigationService.goToFlightAvailabilityPage();
	}

	canBook() {
		return !this.corporate || this.corporateAuthorityToBook;
	}

	verifyButton(form) {
		return form.form.invalid || !this.canBook();
	}

    isPhoneNumberValid() {
        return this.phonePattern.test(this.user.phone_number);
    }
}
