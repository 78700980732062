import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BaseService } from "@app/shared/services/base.service";
import { CONFIRMATION_BOOKING_WAITING_MESSAGE } from "@app/shared/services/display.messages";
import { B2B_ROUTE, MANAGE_VISA_REQUEST } from "@app/shared/services/routes.pages";
import { ShareVars } from "@app/shared/services/share.vars";
import { VisaApplicationService } from "@app/shared/services/visa.application.service";
import { filter } from "rxjs/operators";



@Component({
	selector: "app-visa-b2b-review",
	templateUrl: "./visa-b2b-review.component.html",
	styleUrls: ["./visa-b2b-review.component.scss"],
})
export class VisaB2bReviewComponent {
	passengerList;
	passenger;
	imageList = [];
	showImagesModal = false;
	selectedImage = {};
	isSubmitting= false;

	constructor(
		private visaService: VisaApplicationService,
		private baseService: BaseService,
		private router: Router,
	) {
		this.visaService.passengers$.pipe(filter((data) => !!data)).subscribe((passengers) => {
			this.passengerList = passengers;
			passengers.forEach((passenger, index) => {
				this.imageList[index] = [];
				for (let i = 0; i < passenger.documents.length; i++) {
					const readerPic = new FileReader();

					readerPic.onload = (e) => {
						this.imageList[index].push(readerPic.result as string);
					};

					readerPic.readAsDataURL(passenger.documents[i]);
				}
			});
		});
	}

	openImagesModal(index, indexImage) {
		this.selectedImage = this.imageList[index][indexImage];
		this.showImagesModal = true;
	}

	closeImagesModal() {
		this.selectedImage = null;
		this.showImagesModal = false;
	}

	submitVisa() {
		if (this.isSubmitting) return
		ShareVars.showChildLoader = true;
		ShareVars.childLoaderMessage = CONFIRMATION_BOOKING_WAITING_MESSAGE;
		this.visaService._imageDoc.next(this.imageList);
		this.isSubmitting = true;
		this.visaService.submitVisaForm().subscribe(
			(data) => {
			ShareVars.showChildLoader = false;
			ShareVars.childLoaderMessage = null;
			this.router.navigate([`${B2B_ROUTE}${MANAGE_VISA_REQUEST}${data.id}`]);
		},
		(err:any) => {
			console.log(err);
		},
		() => {
			console.log("completed");
			this.isSubmitting = false;
			ShareVars.showChildLoader = false;
		}

	);
	}

	startsWith = (word, str) => String(word).startsWith(str);

	isArabic() {
		return this.baseService.isArabic();
	}
}
