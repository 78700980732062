import { Component, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { environment } from "environments/environment";
@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	clientName = "";
	currentYear: number = new Date().getFullYear();
	inLoginPage: boolean = false;

	RubriquesList4winds=[
		{
			title:"FWT",
			link:"https://fourwindstravels.dz/four-winds-travels/"
		},
		{
			title:"Touring Club d’Algérie",
			link:"https://fourwindstravels.dz/touring-club-algerie/"
		},
		{
			title:"Agences",
			link:"https://fourwindstravels.dz/agences/"
		},
		{
			title:"Engagements",
			link:"https://fourwindstravels.dz/engagements/"
		},
		{
			title:"Programme 2024",
			link:"https://fourwindstravels.dz/programme-2024/"
		},
		{
			title:"Corporate",
			link:"https://fourwindstravels.dz/corporate/"
		},
		{
			title:"Destinations DZ",
			link:"https://fourwindstravels.dz/destinations-dz/"
		},
		{
			title:"Contact",
			link:"https://fourwindstravels.dz/contact/"
		}
	]

	AgencesList4winds=[
		{
			title:"Agence Les Vergers - British Airways et Iberia",
			link:"https://www.google.com/maps/place/Four+winds+travels+Direction+g%C3%A9n%C3%A9rale,+%D8%A7%D9%84%D8%AC%D9%87%D8%A7%D8%AA+%D8%A7%D9%84%D8%A7%D8%B1%D8%A8%D8%B9+%D9%84%D9%84%D8%A3%D8%B3%D9%81%D8%A7%D8%B1+%2FFWT%E2%80%AD/@36.7775063,2.982851,13z/data=!4m10!1m2!2m1!1sFour+Winds+Travels!3m6!1s0x128fad95cd572a59:0xd63552b5e1d38c8a!8m2!3d36.7287644!4d3.0573992!15sChJGb3VyIFdpbmRzIFRyYXZlbHNaFCISZm91ciB3aW5kcyB0cmF2ZWxzkgELdG91cl9hZ2VuY3ngAQA!16s%2Fg%2F11l1mdjjr3?entry=ttu"
		},
		{
			title:"Agence Les Vergers - Transavia",
			link:"https://www.google.com/maps/place/Four+winds+travels+Direction+g%C3%A9n%C3%A9rale,+%D8%A7%D9%84%D8%AC%D9%87%D8%A7%D8%AA+%D8%A7%D9%84%D8%A7%D8%B1%D8%A8%D8%B9+%D9%84%D9%84%D8%A3%D8%B3%D9%81%D8%A7%D8%B1+%2FFWT%E2%80%AD/@36.7775063,2.982851,13z/data=!4m10!1m2!2m1!1sFour+Winds+Travels!3m6!1s0x128fad95cd572a59:0xd63552b5e1d38c8a!8m2!3d36.7287644!4d3.0573992!15sChJGb3VyIFdpbmRzIFRyYXZlbHNaFCISZm91ciB3aW5kcyB0cmF2ZWxzkgELdG91cl9hZ2VuY3ngAQA!16s%2Fg%2F11l1mdjjr3?entry=ttu"
		},
		{
			title:"Agence Pasteur",
			link:"https://www.google.com/maps/place/Four+Winds+Travels/@36.7775063,2.982851,13z/data=!4m10!1m2!2m1!1sFour+Winds+Travels!3m6!1s0x128fb2587dcd99f5:0x9dd0a989ee815805!8m2!3d36.7737169!4d3.058512!15sChJGb3VyIFdpbmRzIFRyYXZlbHOSAQt0b3VyX2FnZW5jeeABAA!16s%2Fg%2F11ggsg1khb?entry=ttu"
		},
		{
			title:"Agence Abane Ramdane",
			link:"https://www.google.com/maps/place/Four+Winds+Travels/@36.7775063,2.982851,13z/data=!4m10!1m2!2m1!1sFour+Winds+Travels!3m6!1s0x128fb2f85f1a69e9:0xfbb060897fc0268b!8m2!3d36.7775063!4d3.0590687!15sChJGb3VyIFdpbmRzIFRyYXZlbHOSAQ10cmF2ZWxfYWdlbmN54AEA!16s%2Fg%2F1tk5qh22?entry=ttu"
		},
		{
			title:"Agence Oran",
			link:"https://maps.app.goo.gl/rpoYYVe4HwY3nght8"
		},
		{
			title:"Agence Ghardaia",
			link : "https://maps.app.goo.gl/EiuuYWuTQDzKqRnT6"
		}

	]

	airlinesList4winds=[
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/air-canada-logo-black-and-white-1024x343.png",
			alt : "Air Canada",
			link:"https://fourwindstravels.dz/compagnies/air-canada"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2024/06/British-2.png",
			alt : "British Airways",
			link:"https://fourwindstravels.dz/compagnies/british-airways"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/iberia.webp",
			alt : "Iberia",
			link:"https://fourwindstravels.dz/compagnies/iberia"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/ITA-Airways-Logo1-1024x255.webp",
			alt : "ITA Airways",
			link:"https://fourwindstravels.dz/compagnies/ita-airways"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/11/Design-sans-titre-e1700059644831.png",
			alt : "nouvlair",
			link:"https://fourwindstravels.dz/compagnies/nouvel-air"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/Transavia_logo_white-1024x200.png",
			alt : "Transavia",
			link:"https://fourwindstravels.dz/compagnies/transavia"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/11/Design-sans-titre-1-e1700060068437.png",
			alt : "turkish airlines",
			link:"https://fourwindstravels.dz/compagnies/turkish-airlines"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/logo-volotea.png",
			alt : "volotea",
			link:"https://fourwindstravels.dz/compagnies/volotea"
		},
		{
			src:"https://fourwindstravels.dz/wp-content/uploads/2023/10/Vueling.webp",
			alt : "Vueling",
			link:"https://fourwindstravels.dz/compagnies/vueling"
		}
	]

	constructor(public baseService: BaseService) {
		this.clientName = environment.clientDisplayName.toUpperCase();
		if (document.location.href.includes("login")) {
			this.inLoginPage = true;
		}
	}



	ngOnInit() {
		
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	goToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	setLanguage(lang: string) {
		this.baseService.setLanguage(lang);
	}
}
