import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap, map, catchError, switchMap } from "rxjs/operators";
import { WebService } from "@app/web.service";
import { TranslateService } from "@ngx-translate/core";
import { throwError, BehaviorSubject, of } from "rxjs";
import { ShareVars } from "./share.vars";
import { DateService } from "./date.service";
import {
	ERROR_MESSAGE_GETTING_DATA,
	ERROR_INTERNET_CONNECTION,
	ERROR_ACTION_NOT_AUTHORIZED,
	ERROR_ACTION_NOT_AUTHORIZED_TITLE,
	ERROR_MESSAGE_INSUFFICIENT_FUNDS,
	ERROR_ACTION_FUNDS_TITLE,
} from "./display.messages";
import {
	USER_API_URL,
	FLIGHT_PRINT_BOOKING_B2C,
	HOTEL_PRINT_BOOKING_B2C,
	PACKAGE_PRINT_BOOKING_B2C,
	FLIGHT_SEND_BOOKING_BYEMAIL_B2C_URL,
	HOTEL_SEND_BOOKING_BYEMAIL_B2C_URL,
	PACKAGE_SEND_BOOKING_BYEMAIL_B2C_URL,
} from "./urls_b2c";
import {
	USER_SESSION,
	FLIGHT_SEND_BOOKING_BYEMAIL_B2B_URL,
	HOTEL_SEND_BOOKING_BYEMAIL_B2B_URL,
	FLIGHT_PRINT_BOOKING_B2B,
	PACKAGE_SEND_BOOKING_BYEMAIL_B2B_URL,
	HOTEL_PRINT_BOOKING_B2B,
	PACKAGE_PRINT_BOOKING_B2B,
	CHARTER_PRINT_BOOKING_B2B,
	AGENT_B2B_URL,
	AGENCY_AGENCIES_B2B,
	AGENCY_PLATFORMS_B2B,
	API,
} from "./urls_b2b";
import { Location } from "@angular/common";
import { NotyfService } from "ng-notyf";
import { NavigationService } from "app/shared/services/navigation.service";

const TAG = "baseService::";

const KEY_TOKEN = "token";
const KEY_USER = "user";
const KEY_LANGUAGE = "language";

export const ENGLISH = "en";
export const FRENCH = "fr";
export const ARABIC = "ar";
const DEFAULT_LANGUAGE = FRENCH;

export type Language = "en" | "fr" | "ar";

const PLATFORM_MOBILE = "mobile";
const PLATFORM_WEB = "web";

const FLIGHT = "flight";
const PACKAGE = "package";
const HOTEL = "hotel";
const VISA = "visa";
const CHARTER = "charter";

const URL_FLIGHT_PRINT_BOOKING_B2C = FLIGHT_PRINT_BOOKING_B2C;
const URL_HOTEL_PRINT_BOOKING_B2C = HOTEL_PRINT_BOOKING_B2C;
const URL_PACKAGE_PRINT_BOOKING_B2C = PACKAGE_PRINT_BOOKING_B2C;
const URL_FLIGHT_SEND_BOOKING_BYEMAIL_B2C = FLIGHT_SEND_BOOKING_BYEMAIL_B2C_URL;
const URL_HOTEL_SEND_BOOKING_BYEMAIL_B2C = HOTEL_SEND_BOOKING_BYEMAIL_B2C_URL;
const URL_PACKAGE_SEND_BOOKING_BYEMAIL_B2C = PACKAGE_SEND_BOOKING_BYEMAIL_B2C_URL;

const URL_FLIGHT_PRINT_BOOKING_B2B = FLIGHT_PRINT_BOOKING_B2B;
const URL_HOTEL_PRINT_BOOKING_B2B = HOTEL_PRINT_BOOKING_B2B;
const URL_PACKAGE_PRINT_BOOKING_B2B = PACKAGE_PRINT_BOOKING_B2B;
const URL_CHARTER_PRINT_BOOKING_B2B = CHARTER_PRINT_BOOKING_B2B;
const URL_FLIGHT_SEND_BOOKING_BYEMAIL_B2B = FLIGHT_SEND_BOOKING_BYEMAIL_B2B_URL;
const URL_HOTEL_SEND_BOOKING_BYEMAIL_B2B = HOTEL_SEND_BOOKING_BYEMAIL_B2B_URL;
const URL_PACKAGE_SEND_BOOKING_BYEMAIL_B2B = PACKAGE_SEND_BOOKING_BYEMAIL_B2B_URL;

@Injectable({
	providedIn: "root",
})
export class BaseService {
	paxType: string = null;
	b2bUser = new BehaviorSubject(null);
	b2bUserType = "";
	isB2CEnabled = new BehaviorSubject(true);
	agency = new BehaviorSubject(null);
	unreadMessages = 0;
	allow_epayment = false;
	currentPlatform = null;

	constructor(
		private httpClient: HttpClient,
		private platformService: WebService,
		private translator: TranslateService,
		private location: Location,
		private Notyf: NotyfService,
		private navigationService: NavigationService
	) {
		this.b2bUserType = environment.mode;

		if (this.getUserTypeCookies() && this.getUserTypeCookies() == "B2B") {
			ShareVars.waitingB2BUser = true;
			this.b2bUserType = "B2B";
		}
	}

	putRequestCode(url, headers, putData) {
		url = this.addLanguageToUrl(url);
		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			return this.httpClient
				.put(url, putData, { headers, observe: "response" })
				.pipe(map(this.successTokenRequest), catchError(this.errorTokenRequest));
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	postRequestCode(url, headers, postData) {
		url = this.addLanguageToUrl(url);
		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			return this.httpClient
				.post(url, postData, { headers, observe: "response" })
				.pipe(map(this.successTokenRequest), catchError(this.errorTokenRequest));
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	getRequestCode(url, headers, handleError = true) {
		url = this.addLanguageToUrl(url);
		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			return this.httpClient.get(url, { headers, observe: "response" }).pipe(
				map(this.successTokenRequest),
				catchError((error) => {
					if (handleError) {
						return this.errorTokenRequest(error);
					}
					return throwError(error);
				})
			);
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	deleteRequestCode(url, headers) {
		url = this.addLanguageToUrl(url);
		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			return this.httpClient.delete(url, { headers, observe: "response" }).pipe(
				map(this.successTokenRequest),
				catchError((error) => {
					return this.errorTokenRequest(error);
				})
			);
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	selectPlatformKey(): string {
		return environment.key;
	}

	createRequestHeaders(token: string) {
		if (token == null) {
			token = this.getToken();
		}
		// TODO find a good way to detect if we are on b2b or b2c, because i think there is no clear way to do it at the moment
		// let platformKey = environment.key;
		let platformKey = this.selectPlatformKey();
		let headers = new HttpHeaders({ token: token, key: platformKey });
		return headers;
	}

	setPutNewToken(data, url, putData) {
		data = this.successTokenRequest(data);
		let token;

		if (data["session"]) {
			token = data["session"]["token"];
		} else {
			token = "";
		}

		this.setToken(token);
		const headers = this.createRequestHeaders(token);
		return this.putRequestCode(url, headers, putData);
	}

	setPostNewToken(data, url, postData): any {
		data = this.successTokenRequest(data);
		let token;

		if (data["session"]) {
			token = data["session"]["token"];
		} else {
			token = "";
		}
		this.setToken(token);
		const headers = this.createRequestHeaders(token);
		return this.postRequestCode(url, headers, postData);
	}

	setGetNewToken(data, url): any {
		data = this.successTokenRequest(data);
		ShareVars.authorizations = data["product_authorizations"] || data["authorizations"] || [];
		const token = data["session"] ? data["session"]["token"] : "";
		this.setToken(token);
		const headers = this.createRequestHeaders(token);
		return this.getRequestCode(url, headers);
	}

	setDeleteNewToken(data, url): any {
		data = this.successTokenRequest(data);
		const token = data["session"] ? data["session"]["token"] : "";
		this.setToken(token);
		const headers = this.createRequestHeaders(token);
		return this.deleteRequestCode(url, headers);
	}

	tokenPostRequest(url, postData) {
		const token = this.getToken();
		const headers = this.createRequestHeaders(token);

		if (!token && this.b2bUserType == "B2C") {
			return this.getUserToken(headers).pipe(
				switchMap((tokenData) => this.setPostNewToken(tokenData, url, postData))
			);
		} else {
			return this.postRequestCode(url, headers, postData);
		}
	}

	tokenPostLoginRequest(url, postData) {
		const token = this.getToken();
		const headers = this.createRequestHeaders(token);
		return this.postRequestCode(url, headers, postData);
	}

	addLanguageToUrl(url) {
		const separator = url.indexOf("?") === -1 ? "?" : "&";
		url = url + separator + "language=" + this.getLanguage();
		return url;
	}

	tokenGetRequest(url, handleError = true): any {
		const token = this.getToken();
		const headers = this.createRequestHeaders(token);

		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			if (!token && url != USER_API_URL && this.b2bUserType == "B2C") {
				return this.getUserToken(headers).pipe(
					switchMap((tokenData) => this.setGetNewToken(tokenData, url))
				);
			} else {
				return this.getRequestCode(url, headers, handleError);
			}
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	tokenPutRequest(url, putData) {
		const token = this.getToken();
		const headers = this.createRequestHeaders(token);

		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			if (!token && this.b2bUserType == "B2C") {
				return this.getUserToken(headers).pipe(
					switchMap((tokenData) => this.setPutNewToken(tokenData, url, putData))
				);
			} else {
				return this.putRequestCode(url, headers, putData);
			}
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	tokenDeleteRequest(url) {
		const token = this.getToken();
		const headers = this.createRequestHeaders(token);
		if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
			if (!token && url != USER_API_URL && this.b2bUserType == "B2C") {
				return this.getUserToken(headers).pipe(
					switchMap((tokenData) => this.setDeleteNewToken(tokenData, url))
				);
			} else {
				return this.deleteRequestCode(url, headers);
			}
		} else {
			const error = new HttpErrorResponse({ status: 401 });
			return of(error) as any;
		}
	}

	successTokenRequest(data) {
		let response = data;

		if (data["body"]) {
			response = data.body;
		}
		let result = response;
		if (response["result"]) {
			result = response["result"];
		}
		if (result["error"]) {
			if (
				result["error"]["code"] !== "NO_AVAIL_FOUND" &&
				!ShareVars.excludeErrors.includes(result["description"]) &&
				result["error"] != "The returned response is empty"
			) {
				if (result["error"]["messages"] && result["error"]["messages"].length) {
					ShareVars.errorMessage = result["error"]["messages"][0]["txt"];
				} else if (result["description"]) {
					ShareVars.errorMessage = result["description"];
				} else if (result["error"]["message"]) {
					ShareVars.errorMessage = result["error"]["message"];
				} else {
					return response;
				}
				ShareVars.showError = true;
				ShareVars.errorTitle = "ERROR";
			}
		}
		return response;
	}

	errorTokenRequest(errorReq: HttpErrorResponse) {
		console.log("Log Error:", errorReq);
		const errorResponse = {
			errorStatus: null,
			errorMSG: null,
			errorSide: null,
		};

		ShareVars.errorStatus = null;
		errorResponse.errorMSG = errorReq.message;
		errorResponse.errorStatus = errorReq.status;

		if (errorReq.error instanceof ErrorEvent) {
			errorResponse.errorSide = "Client side error";
			ShareVars.showError = true;
			ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
		} else {
			errorResponse.errorSide = "Server side error";

			if (errorReq.error instanceof ProgressEvent) {
				ShareVars.showError = true;
				ShareVars.errorMessage = ERROR_INTERNET_CONNECTION;
				return throwError(errorResponse);
			}

			ShareVars.errorStatus = errorReq.status;
			ShareVars.showError = true;
			ShareVars.showChildLoader = false;
			ShareVars.childLoaderMessage = null;

			switch (ShareVars.errorStatus) {
				case null:
					break;
				case 401:
					this.deleteB2BUser();
					this.deleteUserTypeCookies();
					ShareVars.waitingB2BUser = false;
					if (this.b2bUserType == "B2B") {
						ShareVars.showError = false;
						this.navigationService.goToB2BLogin();
					} else {
						//show error for b2c only
						ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
						ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
					}

					break;
				case 402:
					ShareVars.errorMessage = ERROR_MESSAGE_INSUFFICIENT_FUNDS;
					ShareVars.errorTitle = ERROR_ACTION_FUNDS_TITLE;
					break;
				case 403:
					ShareVars.errorMessage = ERROR_ACTION_NOT_AUTHORIZED;
					ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
					break;
				case 500:
					ShareVars.showError = false;
					break;
				case 498:
					ShareVars.showError = false;
					let url = "/b2c/change-password";
					if (this.b2bUserType == "B2B") {
						url = "/b2b/change-password";
					}
					window.location.href = url;
					break;
				default:
					ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
					ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
			}
		}
		return throwError(errorResponse);
	}

	logResponse = (funcName, url) =>
		tap(
			(data) => this.logSuccess(funcName, url, data),
			(error) => this.logError(funcName, url, error)
		);

	logSuccess(funcName, url, data) {
		const FUNC_TAG = TAG + funcName + ":";
		const SUCCESS_TAG = FUNC_TAG + " \nurl: " + url + " \ndata: ";
		console.log(SUCCESS_TAG);
		console.dir(data);
	}

	logError(funcName, url, error) {
		const FUNC_TAG = TAG + funcName + ":";
		const ERROR_TAG = FUNC_TAG + " \nurl:" + url + " \nerror: ";
		console.log(ERROR_TAG);
		console.dir(error);
	}

	saveData(key, value) {
		if (typeof value !== typeof "") {
			value = JSON.stringify(value);
		}
		this.platformService.saveData(key, value);
	}

	getData = (key) => this.platformService.getData(key);

	deleteData = (key) => {
		this.platformService.deleteData(key);
	};

	setToken = (token: string) => {
		token ? this.saveData(KEY_TOKEN, token) : null;
	};

	getToken = () => this.getData(KEY_TOKEN);

	deleteToken = () => {
		this.deleteData(KEY_TOKEN);
	};

	setUser = (user) => (user ? this.platformService.saveData(KEY_USER, user) : null);

	getUser = () => this.platformService.getData(KEY_USER);

	deleteUser = () => {
		this.platformService.deleteData(KEY_USER);
	};

	getLanguage() {
		const lang = this.platformService.getData(KEY_LANGUAGE);
		if (lang) {
			return lang;
		}
		return this.initTrnaslator();
	}

	setLanguage(language) {
		if (!language) {
			return;
		}
		this.platformService.saveData(KEY_LANGUAGE, language);
		this.translator.use(language);
	}

	isArabic = () => this.getLanguage() === ARABIC;

	isFrench = () => this.getLanguage() === FRENCH;

	isEnglish = () => this.getLanguage() === ENGLISH;

	initTrnaslator() {
		const langs = ["en", "fr", "ar"];
		this.translator.addLangs(langs);
		this.translator.setDefaultLang(DEFAULT_LANGUAGE);
		this.setLanguage(DEFAULT_LANGUAGE);
		return DEFAULT_LANGUAGE;
	}

	initLanguage = () => {
		this.getLanguage() ? this.translator.use(this.getLanguage()) : this.initTrnaslator();
	};

	getGPSPosition = () => this.platformService.getGPSPostion();

	isMobile = () => this.platformService.getPlatformName() === PLATFORM_MOBILE;

	isWeb = () => this.platformService.getPlatformName() === PLATFORM_WEB;

	routerEventsObservable = () => this.platformService.routerEventsObservable();

	getRoutePath = () => this.platformService.getRoutePath();

	private getPaxType = () => this.paxType;

	setPaxType = (type) => {
		this.paxType = type;
	};

	isEmptyPax = (): boolean => this.getPaxType() == null;

	isFlightPax = (): boolean => this.getPaxType() == FLIGHT;

	isPackagePax = (): boolean => this.getPaxType() == PACKAGE;

	isHotelPax = (): boolean => this.getPaxType() == HOTEL;

	isVisaPax = (): boolean => this.getPaxType() == VISA;

	isCharterPax = (): boolean => this.getPaxType() == CHARTER;

	focusOnElement(id: string) {
		const elm: HTMLElement = document.getElementById(id) as HTMLElement;

		if (elm != null) {
			setTimeout(() => {
				elm.click();
				elm.focus();
			}, 10);
		}
	}

	compareDates(date1, date2): number {
		// date1 and date2 must be DateService.toObjectDate result {year: X, month: Y, day: Z}

		let d1 = null;
		let d2 = null;

		d1 = DateService.toNewDate(date1).getTime();
		d2 = DateService.toNewDate(date2).getTime();

		if (d1 > d2) {
			return 1;
		} else if (d1 < d2) {
			return -1;
		} else {
			return 0;
		}
	}

	getUserToken = (headers) => this.httpClient.get(USER_API_URL, { headers, observe: "response" });

	fixFlightParamsFromURL(queryParams): any {
		queryParams["bounds"] = +queryParams["bounds"];
		queryParams["nrOfAdult"] = +queryParams["nrOfAdult"];
		queryParams["nrOfChild"] = +queryParams["nrOfChild"];
		queryParams["nrOfInfant"] = +queryParams["nrOfInfant"];
		queryParams["nrOfInfantWithSeat"] = +queryParams["nrOfInfantWithSeat"];
		queryParams["nrOfYouth"] = +queryParams["nrOfYouth"];
		queryParams["nrOfSenior"] = +queryParams["nrOfSenior"];
		queryParams["range"] = +queryParams["range"];
		queryParams["withBaggage"] = +queryParams["withBaggage"];
		queryParams["refundable"] = +queryParams["refundable"];
		queryParams["directFlight"] = +queryParams["directFlight"];
		queryParams["openReturn"] = +queryParams["openReturn"];
		queryParams["requestedFlightNonStop"] = +queryParams["requestedFlightNonStop"];
		queryParams["requestedMultiCity"] = +queryParams["requestedMultiCity"];

		switch (queryParams["flexible"]) {
			case "true":
				queryParams["flexible"] = true;
				break;
			case "false":
				queryParams["flexible"] = false;
				break;
			default:
				queryParams["flexible"] = true;
				break;
		}

		return queryParams;
	}

	fixHotelParamsFromURL(queryParams): any {
		queryParams["adult_total"] = +queryParams["adult_total"];
		queryParams["child_total"] = +queryParams["child_total"];
		queryParams["rooms"] = +queryParams["rooms"];
		queryParams["nights"] = +queryParams["nights"];
		queryParams["totalProviders"] = +queryParams["totalProviders"];
		queryParams["hotelDepartureID"] = +queryParams["hotelDepartureID"];
		if (queryParams["image"]) {
			queryParams["image"] = +queryParams["image"];
		}

		for (let i = 0; i < queryParams["rooms"]; i++) {
			queryParams["adults_nbr_" + (i + 1)] = +queryParams["adults_nbr_" + (i + 1)];
			queryParams["child_nbr_" + (i + 1)] = +queryParams["child_nbr_" + (i + 1)];
		}
		return queryParams;
	}

	formatPrice(price: number): string {
		let priceType = "";
		let newPrice = "";
		let decimalPart;

		if (price < 0) {
			priceType = "- ";
		}

		price = Math.abs(price);

		(price + "").indexOf(".") !== -1
			? (decimalPart = (price + "").slice(
					(price + "").indexOf(".") + 1,
					(price + "").indexOf(".") + 3
			  ))
			: (decimalPart = "00");

		price = Math.floor(price);
		const priceString = price + "";
		let priceArray = priceString.split("");
		priceArray = priceArray.reverse();

		for (let i = 0; i < priceArray.length; i++) {
			newPrice += priceArray[i];

			if ((i + 1) % 3 == 0 && i > 0 && i + 1 != priceArray.length) {
				newPrice += ".";
			}
		}

		const newPriceArray = newPrice.split("");
		newPrice = newPriceArray.reverse().join("");

		return priceType + newPrice + "," + decimalPart;
	}

	formatSatimPrice(price: number): string {
		
		let priceString = this.formatPrice(price);

		let cleanPrice = priceString.replace(/\./g, '').replace(',', '.');

		let prc = parseFloat(cleanPrice).toFixed(2);
	  
		let [integerPart, decimalPart] = prc.split('.');
	  
		let formattedPrice = `${integerPart}.${decimalPart} DA`;
	  
		return formattedPrice;
	}

	formatDates(date: string): string {
		const dd = date.slice(8, 10);
		const mm = date.slice(5, 7);
		const yy = date.slice(0, 4);

		return dd + "-" + mm + "-" + yy;
	}

	resetPassword(url, token, data) {
		const headers = this.createRequestHeaders(token);
		return this.postRequestCode(url, headers, data);
	}

	setUserTypeCookies = (data) => {
		this.saveData("type", data);
	};

	getUserTypeCookies = () => this.getData("type");

	deleteUserTypeCookies = () => {
		this.deleteData("type");
	};

	setB2BUser = (data) => {
		this.b2bUser.next(data);
	};

	getB2BUser = () => this.b2bUser;

	deleteB2BUser = () => {
		this.b2bUser = new BehaviorSubject(null);
	};

	getB2BUserService = () => this.tokenGetRequest(USER_SESSION);

	isB2BUserCookies = (): boolean => this.b2bUserType == "B2B";

	getPrintBookingUrl(id, token, mode, type) {
		let printURL = eval("URL_" + type + "_PRINT_BOOKING_" + mode);
		let identifier = "reference";
		if (type == "FLIGHT" || type == "CHARTER") {
			identifier = "id";
		}
		if (environment.assets=="wiigo"){
			printURL = API + "hotel/printNewVoucher";
		}
		printURL = printURL + "?" + identifier + "=" + id + "&token=" + token;
		return printURL;
	}

	printBooking(id, token, mode, type) {
		window.open(this.getPrintBookingUrl(id, token, mode, type), "_blank");
	}

	sendBookingByEmail(id, email, mode, type) {
		let url = eval("URL_" + type + "_SEND_BOOKING_BYEMAIL_" + mode);
		let identifier = "reference";
		if (type == "FLIGHT") {
			identifier = "id";
		}
		url = url + "?email=" + email + "&" + identifier + "=" + id;
		return this.tokenGetRequest(url);
	}

	scrollToTop() {
		const elm: HTMLElement = document.getElementById("nav_container") as HTMLElement;

		if (elm != null) {
			setTimeout(() => {
				elm.scrollIntoView({ behavior: "smooth" });
			}, 150);
		}
	}

	getB2CState = () => this.isB2CEnabled;

	enableB2C = () => {
		this.isB2CEnabled.next(true);
	};

	disableB2C = () => {
		this.isB2CEnabled.next(false);
	};

	removeElementFromArray(myArray: any[], key: string) {
		const index = myArray.indexOf(key, 0);
		if (index > -1) {
			myArray.splice(index, 1);
		}
		return myArray;
	}

	getAgentsList() {
		return this.tokenGetRequest(AGENT_B2B_URL);
	}

	getAgenciesList() {
		return this.tokenGetRequest(AGENCY_AGENCIES_B2B);
	}

	getPlatformsList(type) {
		return this.tokenGetRequest(AGENCY_PLATFORMS_B2B + `?type=${type}`);
	}

	showNotyf(type, message) {
		return this.Notyf[type](
			`${this.translator.instant(message) ? this.translator.instant(message) : message}`
		);
	}

	isB2BUrlUnauthorized(url) {
		return this.b2bUserType == "B2B" && url.indexOf("/b2c") != -1;
	}

	isB2CUrlUnauthorized(url) {
		return this.b2bUserType == "B2C" && url.indexOf("/b2b") != -1;
	}

	hasEnoughBalance(price: number) {
		if (this.b2bUserType === "B2C" || this.paxType != 'hotel') return true
		return this.b2bUserType === "B2B" && (price<=ShareVars.b2bLocalDepositBalance+ShareVars.b2bOverdraw)
	}

	hasMapView() {
		let mv = Object.keys(environment).includes('hasMapView');
		if (mv) {
			return environment['hasMapView'];
		}
		return false;
	}

	preferMapView() {
		return localStorage.getItem('mapView') == '1'
	}
}
