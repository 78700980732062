import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AIRLINE_LOGO_URL } from "@app/shared/services/urls_b2c";
import { BaseService } from "../../services/base.service";
import { FlightManageBookingsService } from "../../../shared/services/flight-manage-bookings.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { environment } from "../../../../environments/environment";
import { ShareVars } from "../../../shared/services/share.vars";
import { IMAGES_URL } from "../../../shared/services/urls_b2b";
import { FlightService } from "@app/shared/services/flight.service";
import { Observable } from "rxjs";

@Component({
	selector: "app-flight-voucher-shared",
	templateUrl: "./flight-voucher-shared.component.html",
	styleUrls: ["./flight-voucher-shared.component.scss"],
})
export class FlightVoucherSharedComponent implements OnInit {
	@Input() modalElement: any;
	@Input() airlineDictionary: any;
	@Input() airportDictionary: any;
	@Input() tripType: string;
	@Input() userData;
	@Input() pageType: string;
	@Input() userB2B;
	printEmail = null;
	@Output() printTicketEvent = new EventEmitter<any>();
	@Output() sendTicketEmailEvent = new EventEmitter<any>();
	@Output() manageBookingsEvent = new EventEmitter<any>();
	@Output() goToHomePageEvent = new EventEmitter<any>();

	@Output() confirmBookingEvent = new EventEmitter<any>();
	@Output() transfertTicketEvent = new EventEmitter<any>();
	@Output() synchronizeEvent = new EventEmitter<any>();
	@Output() refundEticketEvent = new EventEmitter<any>();
	@Output() cancelEticketEvent = new EventEmitter<any>();
	@Output() applyPenaltyCancelTicketAdminEvent = new EventEmitter<any>();

	airlineLogoAddress = AIRLINE_LOGO_URL;
	fileExt = ".png";
	iataCode: string;
	checkIn: string;
	checkOut: string;
	showButtons: boolean = false;
	showModalHistory: boolean = false;
	showModalTransfert: boolean = false;
	showCancelAdmin: boolean = false;
	showModalEmail: boolean = false;
	showMiniRules: boolean = false;
	waitingConditionsData: boolean = false;
	noConditionsFound: boolean = false;
	flightMiniRulesModalData = [];
	citiesDictionary;

	sendB2C = 0;
	token;
	headers;

	selectedAgencyIndex = null;

	confirmationModalState: boolean = false;
	confirmationModalTitle: string = "";
	cancelRefundAmount = null;
	cancelFee = null;
	cancelEticketNumber = null;

	crypticCode: string;
	showCrypticModal: boolean = false;

	imagesURL = IMAGES_URL;

	/* pagination */
	page = 1;
	lastSelectedPage = 1;

	// to use it in slice
	paginationStart = 0;
	paginationEnd = 0;

	itemPerPage = 20;
	/* end pagination */
	classDropDownID = [
		"flight_search_class_dropdown_2",
		"flight_search_class_dropdown_4",
		"flight_search_class_dropdown_6",
	];
	classDropDownText = [20, 50, 100];
	eTicketList = [];

	// TODO duplicate
	classCorrespondances = ["M", "Y", "W", "C", "F"];
	classTitles = ["ECONOMY_STANDARD", "ECONOMY", "ECONOMY_PREMIUM", "BUSINESS", "FIRST"];

	constructor(
		private translateService: TranslateService,
		public baseService: BaseService,
		private flightManageBookingsService: FlightManageBookingsService,
		private navigationService: NavigationService,
		private flightService: FlightService,
		private router: Router
	) {}

	ngOnInit() {
		this.paginationEnd = this.page * this.itemPerPage;
		//console.log(this.modalElement);

		this.iataCode = this.modalElement["booking"]["bounds"][0]["destination"];

		//only case 'rt', case ow md not yet set
		if (this.tripType == "rt") {
			this.checkIn = this.modalElement["booking"]["bounds"][0]["arrivalDate"];
			this.checkOut = this.modalElement["booking"]["bounds"][1]["departureDate"];
		}
		
		setTimeout(() => {
			if (environment.assets == "sv") {
				this.toggleButtons();
				this.showButtons = true;
			}
		}, 2000);

		if (this.modalElement.booking.ticket) {
			this.eTicketList = this.modalElement.booking.ticket.etickets
				.filter((eticket) => eticket.status == "CONFIRMED")
				.map((eticket) => eticket.ticket_number);
			this.cancelEticketNumber = this.eTicketList[0];
		}
	}

	getPaginationSize(): number {
		let size: number;
		let modulo: number;

		size = Math.floor(this.modalElement.booking.ticket.history.length / this.itemPerPage);

		modulo = this.modalElement.booking.ticket.history.length % this.itemPerPage;

		return modulo > 0 ? (size + 1) * 10 : size * 10;
	}

	getMiniRulesFromEticket(): Observable<any> {
		return this.flightService.getMiniRulesFromEticket(this.modalElement.booking.pnr);
	}

	changePage() {
		this.paginationEnd = this.page * this.itemPerPage;
		this.paginationStart = this.paginationEnd - this.itemPerPage;
		this.lastSelectedPage = this.page;
	}

	setItemPerpage(event) {
		this.itemPerPage = event;
		this.paginationEnd = this.page * this.itemPerPage;
		this.paginationStart = this.paginationEnd - this.itemPerPage;
		this.lastSelectedPage = this.page;
	}

	timeDifference(fd1, time1, fd2, time2, sort?: string) {
		fd1 = fd1.split("-").reverse();
		fd2 = fd2.split("-").reverse();
		time1 = time1.split(":");
		time2 = time2.split(":");

		fd1 = fd1.map((el) => Number(el));
		fd2 = fd2.map((el) => Number(el));

		let diff: any;
		const date1 = new Date(fd1[2], fd1[1] - 1, fd1[0], time1[0], time1[1]).getTime();
		const date2 = new Date(fd2[2], fd2[1] - 1, fd2[0], time2[0], time2[1]).getTime();
		diff = date2 - date1;
		let msec = Math.abs(diff);
		const hh = Math.floor(msec / 1000 / 60 / 60);
		msec -= hh * 1000 * 60 * 60;
		const mm = Math.floor(msec / 1000 / 60);
		msec -= mm * 1000 * 60;
		const ss = Math.floor(msec / 1000);
		msec -= ss * 1000;

		if (sort && sort == "sort") {
			let newTime = {};
			newTime["hours"] = hh;
			newTime["minutes"] = mm;
			return newTime;
		} else {
			return (
				hh +
				" " +
				this.translateService.instant("HOURS") +
				" " +
				mm +
				" " +
				this.translateService.instant("MINUTES")
			);
		}
	}

	formatFlightPrice(price: number): string {
		return this.baseService.formatPrice(price);
	}


	openEmailModal() {
		this.sendB2C = 0;
		this.showModalEmail = true;
	}

	openHistory() {
		this.showModalHistory = true;
	}

	openTransfert() {
		this.showModalTransfert = true;
	}

	sendBookingByEmail(eticket, flightConfirmationEmailModal) {
		flightConfirmationEmailModal.closeModal();
		this.sendTicketEmailEvent.emit({ eticket, email: this.printEmail });
	}

	goToFlightManageBookingsPage(id) {
		this.manageBookingsEvent.emit(id);
	}

	openConfirmModal(title, modalName?) {
		this.confirmationModalState = true;
		this.confirmationModalTitle = title;

		if (modalName) {
			modalName.closeModal();
		}
	}

	openMiniRules(){
		this.waitingConditionsData = true;
		this.showMiniRules = true;
		this.getMiniRulesFromEticket().subscribe(
			(data) => {
				if(data.success){
					this.openConditionsModal(data) ;
				}
			}
		);
	}

	openConditionsModal(data) {
		this.waitingConditionsData = false;
		this.noConditionsFound = false;

		this.citiesDictionary = data["airports"];
		
		if (!data["fares"] || data["fares"].length === 0) {
			this.noConditionsFound = true;
			return;
		}
		
		for (let fare of data["fares"]) {
			this.flightMiniRulesModalData.push(fare);
		}
	}

	selectEticketToCancel(eticketNumber) {
		this.cancelEticketNumber = eticketNumber;
	}

	openCancelAdminModal() {
		this.showCancelAdmin = true;
	}

	closeConfirmModal() {
		this.confirmationModalState = false;
		this.confirmationModalTitle = "";
	}

	closeCancelAdminModal() {
		this.showCancelAdmin = false;
	}

	actionConfirmModal() {
		switch (this.confirmationModalTitle) {
			case "CONFIRM_BOOKING":
				this.confirmBookingEvent.emit();
				break;

			case "REFRESH":
				this.refreshETicket();
				break;

			case "SYNCHRONIZE":
				this.synchronizeEvent.emit();
				break;

			case "REFUND":
				this.refundEticketEvent.emit();
				break;

			case "CANCEL":
				this.cancelEticketEvent.emit(this.modalElement.booking.ticket.id);
				break;

			case "CANCEL_ADMIN_PENALTY":
				this.applyPenaltyCancelTicketAdminEvent.emit({
					cancelEticketNumber: this.cancelEticketNumber,
					cancelRefundAmount: this.cancelRefundAmount,
					cancelFee: this.cancelFee,
				});
				break;
		}

		this.closeConfirmModal();
	}

	refreshETicket() {
		ShareVars.isBusy = true;
		this.flightManageBookingsService
			.refreshETicket(this.modalElement.booking.ticket.id)
			.subscribe((data) => {
				this.modalElement.booking = Object.assign(data.result, this.modalElement.booking);
				ShareVars.isBusy = false;

				this.router.onSameUrlNavigation = "reload";
				this.navigationService.goToB2BFlightETicket(this.modalElement.booking.ticket.id);
			});
	}

	cryptic() {
		if (this.crypticCode === undefined) {
			ShareVars.isBusy = true;
			this.flightManageBookingsService
				.getCryptic(this.modalElement.booking.ticket.id)
				.subscribe(
					(data) => {
						this.crypticCode = data.result.cryptic
							? data.result.cryptic.replaceAll("\n", "<br>")
							: undefined;
						this.showCrypticModal = true;
						ShareVars.isBusy = false;
					},
					(error) => {
						console.error(error);
						ShareVars.isBusy = false;
					}
				);
		} else {
			this.showCrypticModal = true;
		}
	}

	getConfirmationButtonConditions(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}

		return (
			this.modalElement.booking.ticket["pending"] &&
			(!this.modalElement.booking.ticket["booking_need_exchange_documents"] ||
				this.modalElement.booking.ticket["agency"][
					"authorized_to_confirm_bookings_need_exchange_documents"
				] ||
				(this.userB2B &&
					this.userB2B["authorizations"].includes("bookings_admin_authorizations")))
		);
	}

	getTransfertButtonConditions(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			(this.modalElement.booking.ticket["confirmed"] ||
				this.modalElement.booking.ticket["modified"]) &&
			this.userB2B &&
			this.userB2B["authorizations"].includes("bookings_admin_authorizations")
		);
	}

	getCancelButtonConditions(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			(this.modalElement.booking.ticket["confirmed"] ||
				this.modalElement.booking.ticket["modified"] ||
				this.modalElement.booking.ticket["pending"]) &&
			this.modalElement.booking.ticket["booking_can_be_void"]
		);
	}

	getCancelButtonConditionsAdmin(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			(this.modalElement.booking.ticket["confirmed"] ||
				this.modalElement.booking.ticket["modified"] ||
				this.modalElement.booking.ticket["pending"]) &&
			this.pageType == "ticket" &&
			!this.modalElement.booking.ticket["booking_can_be_void"] &&
			this.userB2B["authorizations"].includes("bookings_admin_authorizations")
		);
	}

	getRefreshButtonConditions(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			this.modalElement.booking.ticket["pending"] &&
			this.modalElement.booking.ticket["provider"] == "1A"
		);
	}

	getSynchronizeButtonConditions(): boolean {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			this.modalElement.booking.ticket["modified"] &&
			this.userB2B &&
			this.userB2B["authorizations"].includes("bookings_admin_authorizations") &&
			this.modalElement.booking.ticket["provider"] == "1A"
		);
	}

	getRefundButtonConditions() {
		if (this.modalElement.booking.ticket == undefined) {
			return false;
		}
		return (
			(this.modalElement.booking.ticket["confirmed"] ||
				this.modalElement.booking.ticket["modified"]) &&
			this.userB2B &&
			this.userB2B["authorizations"].includes("bookings_admin_authorizations") &&
			!this.modalElement.booking.ticket["booking_can_be_void"]
		);
	}

	toggleButtons() {
		let left = 80;
		let floatButtons = document.querySelectorAll<HTMLElement>(".soc a");		
		if (this.showButtons == false) {
			floatButtons.forEach((element) => {				
				element.style.left = `${left}px`;
				left = left + 60;
			});
		} else {
			floatButtons.forEach((element) => {
				element.style.left = `0px`;
			});
		}
		this.showButtons = !this.showButtons;
	}

	goToHomePage() {
		this.goToHomePageEvent.emit();
	}

	isArabic(): boolean {
		return this.baseService.isArabic();
	}

	isB2BUser = (): boolean => this.baseService.isB2BUserCookies();

	printReceipt() {
		let id = this.modalElement.bookingKey;
		window.open(
			environment.serverURL + "/api/common/payment/printEpaymentReceipt?id=" + id,
			"_blank"
		);
	}

	showEmailReceiptSender() {
		this.sendB2C = 1;
		this.showModalEmail = true;
	}

	sendReceipt() {
		let id = this.modalElement.bookingKey;
		let url =
			environment.serverURL +
			"/api/common/payment/sendEpaymentReceiptByEmail?email=" +
			this.printEmail +
			"&id=" +
			id;
		this.token = this.baseService.getToken();
		this.headers = this.baseService.createRequestHeaders(this.token);
		this.baseService.getRequestCode(url, this.headers).subscribe((data) => {
			console.log(data);
			this.showModalEmail = false;
		});
	}
}
